import {
  Box,
  Button,
  Container,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import React from "react";
import logo from "../../assets/uretim.jpg";
import montaj from "../../assets/montaj.jpg";
import { Link } from "react-router-dom";

import "./Grids.css";
import { RiHome2Line } from "react-icons/ri";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { PiWindowsLogoThin } from "react-icons/pi";
import { GiGreekTemple } from "react-icons/gi";

export default function Grids() {
  return (
    <>
      <Box
        sx={{ flexGrow: 1, backgroundColor: "#262626", paddingTop: "100px" }}
      >
        <Container>
          <Grid
            container
            sx={{
              backgroundColor: "#262626",
              "--Grid-borderWidth": "2px",
              borderTop: "var(--Grid-borderWidth) solid",
              borderLeft: "var(--Grid-borderWidth) solid",
              borderColor: "#9A8178",
              "& > div": {
                borderRight: "var(--Grid-borderWidth) solid",
                borderBottom: "var(--Grid-borderWidth) solid",
                borderColor: "#9A8178",
                borderWidth: "2px",
              },
            }}
          >
            <Grid size={{ xs: 12, sm: 6 }}>
              <img src={logo} alt="uretim" className="img-uretim" />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignContent: "center",
                //justifyContent: "center",
              }}
              size={{ xs: 12, sm: 6 }}
            >
              <div className="urun-container">
                <h1 className="urun-header">Ürünlerimiz</h1>
                <ul className="urun-list">
                  {cardsData.map((item) => (
                    <li key={item.id}>
                      <Link to={item.link}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
                <br />

                <Button
                  href="/urunler"
                  sx={{
                    padding: "10px 60px",
                    fontFamily: "raleway,sans-serif",
                    color: "black",
                    backgroundColor: "#9A8178",
                  }}
                >
                  BİLGİ
                </Button>
              </div>
            </Grid>
            <Grid
              size={{ xs: 6, sm: 3 }}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <div className="icon-container">
                {" "}
                <RiHome2Line size={80} color="#9A8178" />
                <div className="icon-text">
                  Engin
                  <br />
                  Deneyim
                </div>
              </div>
            </Grid>
            <Grid
              size={{ xs: 6, sm: 3 }}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <div className="icon-container">
                {" "}
                <IoExtensionPuzzleOutline size={80} color="#9A8178" />
                <div className="icon-text">
                  Profesyonel
                  <br />
                  Ekip
                </div>
              </div>
            </Grid>
            <Grid
              size={{ xs: 6, sm: 3 }}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <div className="icon-container">
                {" "}
                <PiWindowsLogoThin size={80} color="#9A8178" />
                <div className="icon-text">
                  Kusursuz
                  <br />
                  Teslim
                </div>
              </div>
            </Grid>
            <Grid
              size={{ xs: 6, sm: 3 }}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <div className="icon-container">
                {" "}
                <GiGreekTemple size={80} color="#9A8178" />
                <div className="icon-text">
                  Sürdürülebilir
                  <br />
                  ve Sorumlu
                </div>
              </div>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <div className="urun-container">
                <h1 className="urun-header">Hakkımızda</h1>

                <span className="urun-subheader">Başkent Özpen</span>
                <br />
                <span className="urun-subheader">PVC</span>
                <Typography
                  sx={{
                    marginTop: "50px",
                    color: "white",

                    marginRight: "15%",
                  }}
                >
                  Başkent Özpen Pvc ve Isıcam Sanayi Ltd.Şti. kuruluşundan
                  itibaren kusursuz müşteri memnuniyeti, profesyonel ekip
                  desteği ve güvenilir satış sonrası hizmet anlayışı ile
                  sektörde ön plana çıkmıştır. Üstün başarı anlayışı ve her
                  geçen gün genişleyen bayi ağı sayesinde Başkent Özpen Pvc ve
                  Isıcam Sanayi Ltd. Şti. 100 çalışanı ile geleceğe daha güvenle
                  bakmaktadır.
                </Typography>
              </div>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <img src={montaj} alt="uretim" className="img-uretim" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
const cardsData = [
  {
    id: 1,
    title: "Pencere ve Kapı Sistemleri",
    text: "Card Content 1",
    img: require("./assets/pencerevekapisistemleri.jpg"),
    link: "/urunler/pencere-ve-kapi-sistemleri",
  },
  {
    id: 2,
    title: "Sürme Sistemleri",
    text: "Card Content 2",
    img: require("./assets/pvc-kapi.jpg"),
    link: "/urunler/surme-sistemleri",
  },
  {
    id: 3,
    title: "Panjur ve Kepenk Sistemleri",
    text: "Card Content 3",
    img: require("./assets/pvc-panjur.jpg"),
    link: "/urunler/panjur-ve-kepenk-sistemleri",
  },
  {
    id: 4,
    title: "Donanım Sistemleri",
    text: "Card Content 4",
    img: require("./assets/pvc-kapi.jpg"),
    link: "/urunler/donanım-sistemleri",
  },
  {
    id: 5,
    title: "Cam Balkon Sistemleri",
    text: "Card Content 5",
    img: require("./assets/cambalkon.jpg"),
    link: "/urunler/cam-balkon-sistemleri",
  },
  // İstediğiniz kadar card ekleyebilirsiniz.
];
