import React from "react";
import Carousel from "react-bootstrap/Carousel";
import bg from "../../assets/ozpen-on.jpeg";
import bg2 from "../../assets/ozpen/Üretim.jpg";
import bg3 from "../../assets/1logo.png";
import "./Home.css";
import Grids from "./Grids";
import { Typography } from "@mui/material";

export default function Home() {
  return (
    <>
      <Carousel fade>
        <Carousel.Item className="carousel-item-custom">
          <img className="d-block w-100 hero-image" src={bg} alt=" One" />
          <Carousel.Caption style={{ backgroundColor: "rgba(0,0,0,0.6)" }}>
            <h3>Başkent Özpen PVC</h3>
            <p>Sizin için en kalitelisini üretiyoruz.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carousel-item-custom">
          <img className="d-block w-100 hero-image" src={bg3} alt=" Two" />
          <Carousel.Caption style={{ backgroundColor: "rgba(0,0,0,0.6)" }}>
            <h3>Hayata Winsa'dan Bak</h3>
            <p>Türkiye'nin en gelişmiş sistemleriyle evinize güzellik katın.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carousel-item-custom">
          <img className="d-block w-100 hero-image" src={bg2} alt=" Three" />
          <Carousel.Caption style={{ backgroundColor: "rgba(0,0,0,0.6)" }}>
            <h3>Üretimde Yenilik</h3>
            <p>Son teknoloji makinalarımızla Türkiye'nin her yerine üretim.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <Grids />
      <Typography sx={{ textAlign: "center", marginTop: "50px" }} variant="h5">
        {" "}
        Bize Ulaşın
      </Typography>
      <iframe
        title="Başkent Özpen"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d33252.626023460936!2d32.74253822149987!3d39.977539853831935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34988ac52cd5d%3A0x948ebc434c04eca2!2zQkHFnktFTlQgw5ZaUEVOIFBWQw!5e0!3m2!1str!2str!4v1730127193232!5m2!1str!2str"
        width="100%"
        height="400"
        style={{ border: 0, marginTop: " 50px" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
}
