// ürünData.js
const urunler = [
  {
    title: "Pencere ve Kapı Sistemleri",
    links: [
      {
        name: "Dorado 76",
        path: "urunler/pencere-ve-kapi-sistemleri/dorado-76",
      },
      {
        name: "Revotech Gold",
        path: "urunler/pencere-ve-kapi-sistemleri/revotech-gold",
      },
      {
        name: "Revotech",
        path: "urunler/pencere-ve-kapi-sistemleri/revotech",
      },
      {
        name: "Safir 76",
        path: "urunler/pencere-ve-kapi-sistemleri/safir-76",
      },
    ],
  },
  {
    title: "Sürme Sistemleri",
    links: [
      {
        name: "Comfort Slide Plus",
        path: "urunler/surme-sistemleri/comfort-slide-plus",
      },
      {
        name: "Comfort Slide",
        path: "urunler/surme-sistemleri/comfort-slide",
      },
      {
        name: "Panorama HS 76",
        path: "urunler/surme-sistemleri/panorama-hs-76",
      },
      {
        name: "Lotus",
        path: "urunler/surme-sistemleri/lotus",
      },
    ],
  },
  {
    title: "Cam Balkon Sistemleri",
    links: [
      {
        name: "Vela",
        path: "urunler/cam-balkon-sistemleri/vela",
      },
      {
        name: "Vela Gold",
        path: "urunler/cam-balkon-sistemleri/vela-gold",
      },
      {
        name: "Veranda",
        path: "urunler/cam-balkon-sistemleri/veranda",
      },
      {
        name: "Camekan",
        path: "urunler/cam-balkon-sistemleri/camekan",
      },
      {
        name: "Camekan Konfor",
        path: "urunler/cam-balkon-sistemleri/camekan-konfor",
      },
      {
        name: "Teras",
        path: "urunler/cam-balkon-sistemleri/teras",
      },
      {
        name: "Teras Konfor",
        path: "urunler/cam-balkon-sistemleri/teras-konfor",
      },
      {
        name: "Teras Kuartet",
        path: "urunler/cam-balkon-sistemleri/teras-kuartet",
      },
      {
        name: "Hareketli Küpeşte Sistemleri",
        path: "urunler/cam-balkon-sistemleri/hareketli-kupeste-sistemleri",
      },
      {
        name: "Dış Mekan Perde Sistemleri",
        path: "urunler/cam-balkon-sistemleri/dis-mekan-perde-sistemleri",
      },
    ],
  },
  {
    title: "Panjur ve Kepenk Sistemleri",
    links: [
      {
        name: "Winstor",
        path: "urunler/panjur-ve-kepenk-sistemleri/winstor",
      },
      {
        name: "Winkepenk",
        path: "urunler/panjur-ve-kepenk-sistemleri/winkepenk",
      },
    ],
  },
  {
    title: "Donanım Sistemleri",
    links: [
      {
        name: "Donanım Sistemleri",
        path: "urunler/donanım-sistemleri",
      },
    ],
  },
];

export default urunler;
